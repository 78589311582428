.videoWrapperOuter {
  max-width: 832px;
  margin-left: auto;
  margin-right: auto;
}
.videoWrapperInner {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 50%;
  padding-top: 25px;
  height: 0;
}
.videoWrapperInner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
