.text-container,
p {
  background-color: #00000096;
  margin: auto;
  padding: 16px;
  text-align: left;
  max-width: 800px;
  line-height: 1.5;
  font-weight: 300;
}

.text-container a {
  color: #ffc364;
  background-color: black;
}

.text-container a:hover {
  color: #00d9ff;
  background-color: black;
}

/* Carousel overrides */

// .carousel .thumb img {
//   width: 100% !important;
//   height: 100% !important;
// }

.slider-wrapper {
  max-width: 600px; /* change this to whatever you want */
}

.carousel .slide img {
  max-height: 600px; /* change this to whatever you want */
  max-width: 600px; /* change this to whatever you want */
  // width: auto;
}

.carousel-root {
  padding-top: 16px;
}

.highlights {
  margin: auto;
  max-width: 800px;
  display: flex;
  font-family: 'Inconsolata', monospace;
  flex-wrap: wrap;

  .highlight-item {
    display: flex;
    width: 200px;
    height: 50px;
    margin: 16px;
    background-color: rgb(227, 227, 227);
    color: #191919;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 8px;

    &:hover {
      background-color: #191919;
      color: white;
      cursor: pointer;
    }
  }
}
