body {
  background-color: rgb(26, 26, 26);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.App {
  text-align: center;
  font-family: "Inconsolata", sans-serif;
  min-height: 100vh;
  color: rgb(255, 255, 255);

  h1 {
    font-size: x-large;
    font-weight: 300;
    margin-bottom: 0;
  }

  h2 {
    font-size: small;
    font-weight: 300;
  }

  .homePage-videos {
    video {
      margin: 8px;
    }
  }

  image-rendering: pixelated;
}

.Qr-Code {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 100px;
  height: 100px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

strong {
  color: rgb(154, 210, 255);
}
