.social-links-container {
    ul {
        padding-left: 0;
    }
}

.social-icons {
    display: inline-block !important;
    padding-right: 15px;
    padding-left: 15px;
    font-size: xxx-large;

    a {
        color: #ffffff82 !important;
    }

    a:hover {
        color: #ffffff !important;
    }
}

.social-links-container {
    justify-content: center !important;
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}
