.morphing {
  text-align: left;
  font-family: "Inconsolata", sans-serif;
  min-height: 100vh;
  color: rgb(255, 255, 255);
  font-size: large;
  padding: 8px;
  background-color: rgb(26, 26, 26);
  line-height: 150%;

  h1 {
    font-size: x-large;
    font-weight: 300;
    margin-bottom: 0;
    color: rgb(106, 170, 255);
  }

  code {
    color: rgb(106, 170, 255);
  }

  h2 {
    font-size: small;
    font-weight: 300;
  }

  .chaos-mirror-diagram {
    width: 400px;
    margin: 8px;

    img {
      width: 100%;
    }
  }

  p {
    font-weight: 200;
  }

  video {
    margin: 8px;
  }

  li {
    line-height: 200%;
  }

  a {
    color: rgb(106, 170, 255);
  }

  .image img{
    margin-top: 16px;
    max-width: 100%;
  }
}
